var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-light-default rounded p-5",attrs:{"id":"service-container-app"},on:{"click":function($event){return _vm.openService(
      _vm.service.slug,
      _vm.service.id,
      _vm.service.service_type,
      _vm.service.name,
      _vm.service.is_drug_testing,
      _vm.service.is_service_by_mail,
      _vm.service.service_type_code,
      _vm.service.show_capture_method,
      _vm.service.enable_ori_rate,
      _vm.service.disable_pii
    )}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4"},[_c('div',{staticClass:"symbol-label",style:('background-size: contain;background-image: url(' +
            _vm.url +
            _vm.service.image +
            ')')})]),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('a',{staticClass:"text-dark-cust font-weight-bolder font-size-lg text-hover-primary-cust mb-1"},[_vm._v(_vm._s(_vm.service.name))]),_c('span',{staticClass:"text-dark-50 font-weight-normal font-size-sm"},[_vm._v(" "+_vm._s(_vm.service.description)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }