<template>
  <section class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid" v-if="!getErrorsStatus">
      <div class="container-fluid">
        <div class="row mb-10">
          <div class="col-lg-10 col-md-12 col-12 offset-lg-1 offset-0">
            <div class="sticky-t-details">
              <div class="row">
                <div class="col-md-12">
                  <v-title :title="'Schedule an Appointment'"></v-title>
                  <div class="form-group">
                    <div class="input-icon">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Find Service"
                        v-model="search"
                        @keyup.enter="SearchData"
                      />
                      <span><i class="flaticon2-search-1 icon-md"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h4 class="page-info">
                    <i
                      v-if="Groupservices.length != 0"
                      class="text-dark fa fa-arrow-circle-left"
                      style="
                        font-size: 22px;
                        margin-right: 10px;
                        cursor: pointer;
                      "
                      @click="gobackGroupservice"
                    ></i>
                    Please select a service/group
                  </h4>
                </div>
              </div>
            </div>
            <div>
              <div
                class="row mt-1"
                v-if="Groupservices.length == 0 && !dataLoading && !flag"
              >
                <div
                  class="col-md-12 order-1 mb-5 text-center"
                  v-if="dataLoading"
                >
                  <loading-spinner style="margin-top: 20px"> </loading-spinner>
                  <h5 class="mt-3">Please wait!</h5>
                </div>
                <div
                  class="col-lg-4 col-md-6 col-sm-12 order-1 mb-3"
                  v-for="(service, index) in filteredServices"
                  :key="index + 10"
                  v-show="
                    filteredServices.length > 0 &&
                      !dataLoading &&
                      ((service.feature_type == 'Group' &&
                        service.object_detail.service_count > 0) ||
                        service.feature_type == 'Service')
                  "
                >
                  <v-service-card
                    :url="url"
                    :service="service"
                    v-on:getGropedService="getGropedService"
                  ></v-service-card>
                </div>

                <div
                  class="col-md-12 order-1 mb-5 text-center"
                  v-show="filteredServices.length <= 0 && !dataLoading"
                >
                  <v-no-record></v-no-record>
                </div>
              </div>
              <div
                class="row mt-1"
                v-if="Groupservices.length > 0 && !dataLoading"
              >
                <div
                  class="col-md-12 order-1 mb-5 text-center"
                  v-if="dataLoading"
                >
                  <loading-spinner style="margin-top: 20px"> </loading-spinner>
                  <h5 class="mt-3">Please wait!</h5>
                </div>

                <div
                  class="col-lg-4 col-md-6 col-sm-12 order-1 mb-3"
                  v-for="(serv, i) in Groupservices"
                  :key="1 + i"
                >
                  <v-group-service-card
                    :url="url"
                    :service="serv"
                  ></v-group-service-card>
                </div>
              </div>
              <div
                class="col-md-12 order-1 mb-5 text-center"
                v-show="Groupservices.length <= 0 && !dataLoading && flag"
              >
                <v-no-record></v-no-record>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="getErrorsStatus">
      <div class="d-flex flex-column flex-root text-center">
        <!--begin::Error-->
        <div class="mt-40">
          <!--begin::Content-->
          <div
            class="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center"
          >
            <h1
              class="error-title color-prim  text-transparent"
              style="
    font-weight: bold;
    font-size: 50px;
"
            >
              404
            </h1>
            <p class="display-4 font-weight-boldest color-prim mb-12">
              How did you get here
            </p>
            <p class="font-size-h1 font-weight-boldest text-dark-75">
              Sorry we can't seem to find the page you're looking for.
            </p>
            <p class="font-size-h4 line-height-md">
              There may be a misspelling in the URL entered <br />
              or the page you are looking for may no longer exist.
            </p>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Error-->
      </div>
    </div>
    <v-copy-right-footer></v-copy-right-footer>
  </section>
</template>

<script>
import vTitle from "@/components/frontend/title";
import vServiceCard from "@/components/frontend/servicecard";
import vGroupServiceCard from "@/components/frontend/groupservicecard";

import vNoRecord from "@/components/frontend/norecord";
import { mapGetters } from "vuex";
import {
  FETCH_F_SERVICES,
  FETCH_F_NOTE,
  FRONTEND_GET_GROUP,
  FRONTEND_SERVICE_SEARCH
} from "@/core/services/store/actions.type";
import loadingSpinner from "@/components/frontend/spinner.vue";
import vCopyRightFooter from "@/components/frontend/copyrightfooter";
// import { RESET_TIMER } from "@/core/services/store/mutations.type";
// import PDF417 from "../../pdf417_js/pdf417";
// import Title from '../../components/frontend/title.vue';
export default {
  components: {
    vTitle,
    vServiceCard,
    vNoRecord,
    loadingSpinner,
    vGroupServiceCard,
    vCopyRightFooter
  },
  data() {
    return {
      searchInput: "",
      loading: false,
      dataLoading: false,
      theValue: "",
      services: [],
      url: "",
      Groupservices: [],
      search: "",
      flag: false
    };
  },
  mounted() {
    // this.$store.commit(RESET_TIMER, 0);
    this.$emit("startclock", 1);
    this.fetchServices();
    if (
      this.getServiceProviderData.fav_icon != null ||
      this.getServiceProviderData.fav_icon != ""
    ) {
      document.querySelector(
        "link[rel='icon']"
      ).href = this.getServiceProviderData.fav_icon;
    }
    this.theValue =
      "HRVHUB30\nHRK\n" +
      "000000000012355\n" +
      "ZELJKO SENEKOVIC\n" +
      "IVANECKA ULICA 125\n" +
      "42000 VARAZDIN\n" +
      "2DBK d.d.\n" +
      "ALKARSKI PROLAZ 13B\n" +
      "21230 SINJ\n" +
      "HR1210010051863000160\n" +
      "HR01\n" +
      "7269-68949637676-00019\n" +
      "COST\n" +
      "Troskovi za 1. mjesec\n";
  },
  computed: {
    filteredServices() {
      let tempServices = this.services;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempServices = tempServices.filter(item => {
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempServices;
    },
    ...mapGetters([
      "getfServices",
      "getClientSlug",
      "getServiceProviderData",
      "getErrorsStatus"
    ])
  },
  methods: {
    getGropedService(val) {
      this.dataLoading = true;
      this.flag = true;

      this.$store
        .dispatch(FRONTEND_GET_GROUP, {
          id: val.id,
          slug: val.client_slug
        })
        .then(data => {
          this.dataLoading = false;

          this.Groupservices = data.data;
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    SearchData() {
      this.dataLoading = true;
      this.flag = true;
      this.$store
        .dispatch(FRONTEND_SERVICE_SEARCH, {
          search_text: this.search,
          slug: this.$route.params.client_slug
        })
        .then(data => {
          this.dataLoading = false;

          this.Groupservices = data.data;
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    gobackGroupservice() {
      this.flag = false;

      this.Groupservices = [];
      this.fetchServices();
    },
    fetchServices() {
      var slug = this.$route.params.client_slug;
      this.$store.dispatch(FETCH_F_NOTE, slug);
      this.dataLoading = true;
      this.flag = false;
      this.$store
        .dispatch(FETCH_F_SERVICES, slug)
        .then(data => {
          if (data.type == "featured") {
            this.services = data.data;
          } else {
            this.Groupservices = data.data;
          }

          this.url = data.url;
          this.dataLoading = false;
        })
        .catch(() => {
          this.dataLoading = false;
        });
    }
  }
};
</script>
